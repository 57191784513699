import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { chinaContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";

const TomiPrice = () => {

  const [balance, setBalance] = useState(null)
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress = Environment.chinaTomi;
  const contract = chinaContract(tokenAddress, web3);


  const tomiPrice = useCallback(
    async () => {
 
      try {
        console.log('tomi',contract,web3);
        const buy = await contract.methods.currentTomiPriceUsdt().call()
        console.log(buy,'buy');
        return buy/10**8;
      } catch (error) {
        console.log(error,'error buy');
        throw error;
      }
    },
    [contract]
  );
  return { tomiPrice: tomiPrice };
};
export default TomiPrice;
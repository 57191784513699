import React, { useState, useEffect } from "react";
import "./banner.scss";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import Navbar from "../header/Navbar";
import Modal from "react-bootstrap/Modal";
import EthPrice from "../../../hooks/chinaFunction/ethPrice";
import TomiPrice from "../../../hooks/chinaFunction/tomiPrice";
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "../../../hooks/useWeb3";
import moment from "moment";
import AllowanceTpl from "../../../hooks/dataFetchers/AllowanceTpl";
import ApproveTpl from "../../../hooks/dataFetchers/ApproveTpl";
import BalacefAccount from "../../../hooks/dataFetchers/BalaceOfAccount";
import BalacefAccountTomi from "../../../hooks/dataFetchers/BalaceOfTomi";
import PurchaseEth from "../../../hooks/chinaFunction/purchaseEth";
import PurchaseUsdt from "../../../hooks/chinaFunction/purchaseUsdt";
import Loader from "../../../hooks/loader";
import axios from "axios";
import { API_URL, BASE_URL, CoingekoBaseURL } from "../../../utils/ApiUrl";
import { toast } from "react-toastify";
// import ERC20 from "../../../utils/balaceAbi.json";
// import {eth} from '../../../utils/ApiUrl'
// import Web3 from 'web3'

const Banner = ({ setShow }) => {
  const code = localStorage?.getItem("code");
  const walletAddress = localStorage?.getItem("ownerAddress");
  const aaa = localStorage?.getItem("userPercentage");
  const [loaderr, setLoarder] = useState(false);
  const { purchaseUsdt } = PurchaseUsdt();
  const { purchaseEth } = PurchaseEth();
  const { approveTpl } = ApproveTpl();
  const { allowanceTpl } = AllowanceTpl();
  const { balacefAccount } = BalacefAccount();
  const { balacefAccountTomi } = BalacefAccountTomi();
  const web3 = useWeb3();
  let now = Date.now();
  const { ethPrice } = EthPrice();
  const { tomiPrice } = TomiPrice();

  const [accessCodeRes, setAccessCodeRes] = useState([]);
  const [inputFeild, setInputField] = useState(null);
  const [inputFeild1, setInputField1] = useState(null);
  const [ethPricevalue, setEthPrice] = useState(0);
  const [tomi, setTomiPrice] = useState(0);
  const [blanceOfEth, setBlanceOfEth] = useState(0);
  const [date, setDate] = useState(0);
  const [mainTag, setMainTag] = useState("ETH");
  const [mainTag1, setMainTag1] = useState("USDT");
  const { account } = useWeb3React();
  const [balanceeUsdt, setBalanceeUsdt] = useState(null);
  const [sallowance, setAllowance] = useState(null);
  const [balacefAccountTomii, setbalacefAccountTomii] = useState(null);
  const [change24hr1, setchange24hr1] = useState(null);
  useEffect(() => {
    firstTimeData();
    alowence();
    get_ApiKeys();
    if(account){
      balaceOf1();
      balaceOf();
      firstTimeData1();
    }else{
      setbalacefAccountTomii(null);
      setBalanceeUsdt(null);
      setBlanceOfEth(null);
    }
  }, [account]);

  useEffect(() => {
    const interval = setInterval(() => {
      console.log('noooooor');
      // Call your function here
      firstTimeData();
      firstTimeData1();
    }, 30000); // 30 seconds in milliseconds

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(interval);
    };
  }, [account]);
console.log(account,'account');
  const firstTimeData = async () => {
    try {
      let a = await ethPrice();
      let b = await tomiPrice();
      // var balance = await web3.eth.getBalance(account);
      setTomiPrice(b);
      setEthPrice(a);
      // setBlanceOfEth(balance/10**18)
      // console.log(balance,'balance');
      let c = now + 604800000;
      // console.log(c,'c', now);
      let cc = new Date(c);
      // console.log(cc);
      const StackIntialDate = moment(cc).format("DD-MM-YYYY H:mm");
      setDate(StackIntialDate);
    } catch (err) {
      console.log(err.message);
    }
  };
  const firstTimeData1 = async () => {
    try {
      // let a= await ethPrice()
      // let b= await tomiPrice()
      var balance = await web3.eth.getBalance(account);
      // setTomiPrice(b)
      // setEthPrice(a)
      setBlanceOfEth(balance / 10 ** 18);
      // console.log(balance,'balance');
    } catch (err) {
      console.log(err.message);
    }
  };
  const [show1, setShow1] = useState(false);
  const handleClose = () => {
    setInputField("");
    setInputField1("");
    setShow1(false);
  };
  const handleShow = () => setShow1(true);
  const changeTag = () => {
    if (mainTag === "ETH") {
      setMainTag("USDT");
      setMainTag1("ETH");
      if (inputFeild) {
        // console.log(inputFeild,'inputFeild');
        // console.log((inputFeild*ethPricevalue)/tomi);
        setInputField1(inputFeild / tomi);
      }
    } else {
      setMainTag("ETH");
      setMainTag1("USDT");
      if (inputFeild) {
        setInputField1((inputFeild * ethPricevalue) / tomi);

        // console.log(inputFeild,ethPricevalue,tomi,(inputFeild*ethPricevalue)/tomi);
      }
    }
  };
  const inputField1Handler = (e) => {
    let value = e.target.value;
    // console.log(value,'e');
    if (mainTag === "ETH") {
      if (value) {
        // if(value<blanceOfEth){
        // console.log('last if ');
        setInputField1((value * ethPricevalue) / tomi);
        setInputField(value);
        // }else{
        //   // setInputField('')
        //   setInputField1('')

        // }
      } else {
        setInputField1("");
        setInputField("");
      }
    } else {
      if (value) {
        // if(value<blanceOfEth){
        // console.log('last if ');
        setInputField1(value / tomi);
        setInputField(value);
        // }else{
        // setInputField('')
        // setInputField1('')

        // }
      } else {
        setInputField1("");
        setInputField("");
      }
    }
  };
  const inputField1Handler1 = (e) => {

    let value = parseFloat(e.target.value);
    // console.log(value,'e');
    if (mainTag === "ETH") {
      if (value) {
        // if(value<blanceOfEth){
        // console.log('last if ');
        setInputField((tomi * value) / ethPricevalue);
        setInputField1(value);
        // }else{
        //   // setInputField('')
        //   setInputField1('')

        // }
      } else {
        setInputField1("");
        setInputField("");
      }
    } else {
      if (value) {
        // if(value<blanceOfEth){
        // console.log('last if ');
        setInputField(tomi * value);
        setInputField1(value);
        // }else{
        // setInputField('')
        // setInputField1('')

        // }
      } else {
        setInputField1("");
        setInputField("");
      }
    }
  };
  const balaceOf = async () => {
    // var balance = await web3.eth.getBalance(account);
    try {
      let balace = await balacefAccount(account);
      // console.log(balace,'balace');
      if (balace) {
        setBalanceeUsdt(balace);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const balaceOf1 = async () => {
    // var balance = await web3.eth.getBalance(account);
    try {
      let balace = await balacefAccountTomi(account);
      // console.log(balace, "balacetotomi");
      if (balace) {
        setbalacefAccountTomii(balace);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const alowence = async () => {
    try {
      let r = await allowanceTpl();
      setAllowance(r);
      // console.log(r,'noor');
    } catch (err) {
      // console.log(err.message,'noor');
    }
  };
  const handlerPop = () => {
    if (inputFeild && inputFeild1) {
      confirm();
    } else {
    }
  };
  const confirm = async () => {
    var config = {
      method: "post",
      url: `${API_URL}/access-codes/buy`,
      data: {
        code: code,
        walletAddress: account,
      },
    };
    let postApiRes = await axios(config)
      .then(function (response) {
        // setAccessCodeRes(response?.data?.data);
        return response?.data?.data;
      })
      .catch(function (error) {
        if (error?.response?.status == 401) {
          // localStorage.removeItem("accessToken");
          // history.push("/");
        }
      });
    setLoarder(true);
    if (mainTag === "ETH") {
      try {
        // console.log("api res: ", postApiRes);
        if (postApiRes) {
          let x = await purchaseEth(parseFloat(inputFeild), postApiRes);
          //  console.log(x,'x');
          if (x) {
            setLoarder(false);
            firstTimeData();
            firstTimeData1();
            balaceOf();
            alowence();
            balaceOf1();
            handleShow();
          }
        } else {
          setLoarder(false);
          toast.error("Some thing went wrong");
        }
      } catch (err) {
        console.log(err, "errr");
        setLoarder(false);
        toast.error("Transaction reverted");
      }
    } else {
      if (sallowance === 0) {
        try {
          let z = await approveTpl(10000000, account);
          if (postApiRes) {
            if (z) {
              let q = await purchaseUsdt(inputFeild, postApiRes);
              if (q) {
                setLoarder(false);
                firstTimeData();
                firstTimeData1();
                balaceOf();
                alowence();
                balaceOf1();
                handleShow();
              }
            }
          } else {
            setLoarder(false);
            toast.error("Some thing went wrong");
          }
        } catch (err) {
          console.log(err, "err");
          setLoarder(false);
          toast.error("Transaction reverted");
        }
      } else {
        try {
          if (inputFeild >= sallowance) {
            if (postApiRes) {
              let res = await approveTpl(0, account);
              if (res) {
                let res11 = await approveTpl(10000000, account);

                if (res11) {
                  let q = await purchaseUsdt(inputFeild, postApiRes);
                  if (q) {
                    setLoarder(false);
                    firstTimeData();
                    firstTimeData1();
                    balaceOf();
                    alowence();
                    balaceOf1();
                    handleShow();
                  }
                }
              }
            } else {
              setLoarder(false);
              toast.error("Some thing went wrong");
            }
          } else {
            // console.log(inputFeild, "inputFeild");
            if (postApiRes) {
              let q = await purchaseUsdt(inputFeild, postApiRes);
              if (q) {
                setLoarder(false);
                firstTimeData();
                firstTimeData1();
                balaceOf();
                alowence();
                balaceOf1();
                handleShow();
              }
            }
            else{
              setLoarder(false);
              toast.error("Some thing went wrong");
            }
          }
        } catch (err) {
          console.log(err, "errr");
          setLoarder(false);
          toast.error("Transaction reverted");
        }
      }
    }
  };

  const get_ApiKeys = async () => {
    try {
      const response = await axios({
        method: "get",
        url: BASE_URL + "users/private-keys",
      });
      GetCoinsList(response?.data?.keys?.AddCustomToken);
      // console.log(
      //   response?.data?.keys?.AddCustomToken,
      //   "response?.data?.keys?.AddCustomToken"
      // );
      // setApiKey(response?.data?.keys?.AddCustomToken);
    } catch (error) {
      // console.log("Error in get_ApiKeys:", await error.response);
      throw error;
    }
  };

  // useEffect(() => {
  //   // || coinArr.length === 0
  //   if (apiKey) {
  //     GetCoinsList();
  //   }
  // }, [apiKey])
  const GetCoinsList = async (e) => {
    let coingeckoList = await axios.get(
      `${CoingekoBaseURL}/coins/list?include_platform=true&x_cg_pro_api_key=${e}`
    );
    let dumArr = coingeckoList.data.filter((i) => {
      // console.log(i);
      if (i?.id === "tominet") {
        return i;
      }
    });
    // console.log(dumArr[0].id, "dumArr");
    coindata(e, dumArr[0].id);
  };

  const coindata = async (e, d) => {
    let res = await axios.get(
      `${CoingekoBaseURL}/coins/${d}?localization=false&tickers=false&market_data=true&community_data=false&developer_data=false&sparkline=false&x_cg_pro_api_key=${e}`
    );
    // console.log(res, "rsdsdes");
    // dta(res)
    let change24hr = res?.data.market_data?.price_change_percentage_24h;
    let curentprice = res.data?.market_data?.current_price?.usd;
    let imageurl = res.data?.image.small;
    setchange24hr1(change24hr);
    // console.log(change24hr, curentprice, imageurl, "fdgdsfgdssf");
  };

  // const dta=async(e)=>{
  //   try {
  //     let total=0
  //     let web3 = new Web3(eth.eth);
  //     const contract = new web3.eth.Contract(
  //       ERC20,
  //       e?.contract_address,
  //     );
  //     let balanceOf = await contract.methods
  //       .balanceOf('0xfE418Af6255568A18ef1868D2B5c386b15D25cc6')
  //       .call();
  //     total = total + parseFloat(balanceOf)
  //     // setBal(balanceOf)
  //     console.log(balanceOf,'balanceOf');
  //   } catch (err) {
  //     try {
  //       // let web3 = new Web3(eth.eth);
  //       // let getbalance = await web3.eth.getBalance(res);
  //       // let balance = web3.utils.fromWei(getbalance, 'ether');
  //       // total = total + parseInt(balance)
  //       // setBal(balance)
  //     } catch (err) {
  //     }
  //   }
  // }

  // console.log("my balancee of tomi", balacefAccountTomii)
  return (
    <>
      {loaderr && (
        <Loader text={"Please wait, your transaction is in process"} />
      )}
      <Navbar setShow={setShow} />
      <section className="main-banner">
        <img
          src="\assets\homepage-bg.png"
          alt="img"
          className="img-fluid banner-bg"
        />
        <div className="container">
          <div className="banner-content">
            <div className="upper-content">
              <div className="left">
                <img
                  src="\assets\tominet-icon.svg"
                  alt="img"
                  className="img-fluid"
                />
                <div className="text">
                  <h6>tomiNET</h6>
                  <p>TOMI</p>
                </div>
              </div>
              <div className="right">
                <h6>${tomi?.toFixed(2)}</h6>
                {change24hr1 > 0 ? (
                  <p>{change24hr1?.toFixed(2)}% (1d)</p>
                ) : (
                  <p className="red">{change24hr1?.toFixed(2)}% (1d)</p>
                )}
              </div>
            </div>
            <div className="bottom-content">
              <h6 className="main-head">Buy</h6>
              <div className="parent-box">
                <img
                  src="\assets\swap-arrow.svg"
                  alt="img"
                  className="img-fluid swap-arrow"
                />
                <div className="inner-box">
                  <div className="left">
                    <div class="dropdown">
                      <button
                        class="dropdown-toggle"
                        type="button"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          src={
                            mainTag === "ETH"
                              ? "/assets/eth-icon.svg"
                              : "/assets/usdt.svg"
                          }
                          alt="img"
                          className="img-fluid suxvsvsyucsusc"
                        />
                        <div className="text">
                          <h6>{mainTag}</h6>
                        </div>
                        <img
                          src="\assets\arrow-down-white.svg"
                          alt="img"
                          className="img-fluid ms-2"
                        />
                      </button>
                      <ul class="dropdown-menu">
                        <li>
                          <a class="dropdown-item gscrxcsx" onClick={changeTag}>
                            <img
                              src={
                                mainTag === "ETH"
                                  ? "/assets/usdt.svg"
                                  : "/assets/eth-icon.svg"
                              }
                              alt="img"
                              className="img-fluid"
                            />{" "}
                            {mainTag1}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="right">
                    <h6>
                      Balance:{" "}
                      <span style={{ color: "#28E664" }}>
                        {mainTag === "ETH"
                          ? blanceOfEth > 0
                            ? blanceOfEth
                                ?.toFixed(4)
                            : "0.000"
                          : balanceeUsdt > 0
                          ? balanceeUsdt
                              ?.toFixed(4)
                          : "0.000"}{" "}
                        {mainTag === "ETH" ? " ETH " : " USDT "}{" "}
                      </span>{" "}
                    </h6>
                    {/* balanceeUsdt?.toFixed(2)?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") */}
                    <input
                      onChange={inputField1Handler}
                      value={inputFeild}
                      type="number"
                      placeholder="0.00"
                    />
                    {/* <p>0.00</p> */}
                  </div>
                </div>
                <div className="inner-box">
                  <div className="left">
                    <img
                      src="\assets\tomi-icon.svg"
                      alt="img"
                      className="img-fluid"
                    />
                    <div className="text">
                      <h6>tomi</h6>
                    </div>
                  </div>
                  <div className="right">
                    <h6>
                      Balance:{" "}
                      <span style={{ color: "#fff" }}>
                        {balacefAccountTomii > 0
                          ? balacefAccountTomii
                              ?.toFixed(4)
                          : "0.000"}
                      </span>
                    </h6>
                    <input 
                      onChange={inputField1Handler1}
                      value={inputFeild1}
                      type="number"
                      placeholder="0.00"
                    />
                    {/* <p>0.00</p> */}
                  </div>
                </div>
              </div>
              <div className="bottom-text">
                <div className="inner-text">
                  <p>Receive:</p>
                  <h6>
                    {inputFeild1
                      ? inputFeild1
                          ?.toFixed(3)
                      : "0.00"}{" "}
                    TOMI
                  </h6>
                </div>
                <div className="inner-text">
                  <p>+{aaa/10}% Bonus:</p>
                  <h6>
                    {inputFeild1
                      ? ((inputFeild1 * (aaa/10)) / 100)
                          ?.toFixed(3)
                      : "0.00"}{" "}
                    TOMI
                  </h6>
                </div>
                <div className="inner-text-bold">
                  <h6>Total:</h6>
                  <h6>
                    {inputFeild1
                      ? (inputFeild1 + (inputFeild1 * (aaa/10)) / 100)
                          ?.toFixed(3)
                      : "0.00"}{" "}
                    TOMI
                  </h6>
                </div>
                <div className="inner-text">
                  <p>Unlock Date:</p>
                  <h6>{date}</h6>
                </div>
              </div>
              {account ? (
                <>
                  {mainTag === "ETH" ? (
                    <>
                      {inputFeild == "" ||
                      inputFeild == null ||
                      blanceOfEth < inputFeild ? (
                        <>
                          <button className=" btn-buy">Buy TOMI </button>
                        </>
                      ) : (
                        <button className="newClasss" onClick={handlerPop}>
                          Buy TOMI{" "}
                        </button>
                      )}
                    </>
                  ) : (
                    // <button className="btn-buy" onClick={handlerPop}>Buy TOMI....</button>
                    <>
                      {inputFeild == "" ||
                      inputFeild == null ||
                      balanceeUsdt < inputFeild ? (
                        <button className=" btn-buy">Buy TOMI </button>
                      ) : (
                        <button className="newClasss" onClick={handlerPop}>
                          Buy TOMI{" "}
                        </button>
                      )}
                    </>
                  )}
                </>
              ) : (
                <button className="btn-buy" onClick={() => setShow(true)}>
                  Buy TOMI
                </button>
              )}
            </div>
          </div>
        </div>
      </section>

      <Modal
        className="connectwallet-modal buy-modal"
        show={show1}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Purchased</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="buy-modal">
            <img src="\assets\purchased.svg" alt="img" className="img-fluid" />
            <p>
              You have successfully purchased{" "}
              {inputFeild1
                ? (inputFeild1 + (inputFeild1 * (aaa/10)) / 100)
                    ?.toFixed(3)
                : "0.00"}{" "}
              TOMI for {inputFeild} {mainTag === "ETH" ? " ETH " : " USDT "}
              You can claim your tomi on <span>{date}</span>
            </p>
            <button className="btn-common" onClick={handleClose}>
              Okay
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Banner;

import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { chinaContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";

const Claim1 = () => {

  const [balance, setBalance] = useState(null)
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress = Environment.chinaTomi;
  const contract = chinaContract(tokenAddress, web3);


  const claim1 = useCallback(
    async (amount) => {
        console.log(amount,'amount');
        // let nd = web3.utils.toWei(amount.toString(), "mwei");
        // console.log(a,b,c,d,e,r,'a,b,c,d,e');
    //   amount = amount.toString()
    // console.log(interAccount,random.toString(),time.toString(),v,r,s,'interAccount,random.toString(),time.toString(),v,r,s');
      try {
        // console.log(nd,selectedItem,num.toString(),now.toString(),v,r,s,'amount,selectedItem,now,num,v,r,s');
        const gas = await contract.methods.claimTomi(amount).estimateGas({
            from:account });
        const details = await contract.methods.claimTomi(amount).send({
          from: account,
          gas,
        });
        return details;
        // console.log('sdsdss' ,  buy)
        // return details;
      } catch (error) {
        throw error;
      }
    },
    [contract, account]
  );
  return { claim1: claim1 };
};
export default Claim1;
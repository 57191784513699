import web3NoAccount from "./web3";
import ChinaAbi from "./chinaAbi.json";
import BusdAbi from "./busdAbi.json";
import TomiAbi from "./tomiAbi.json";
const getContract = (abi, address, web3) => {
  const _web3 = web3;
  // console.log('_web3',_web3);
  return new _web3.eth.Contract(abi, address);
};

export const chinaContract = (address, web3) => {
  return getContract(ChinaAbi, address, web3);
};

export const usdtContract = (address, web3) => {
  return getContract(BusdAbi, address, web3);
};
export const tomiContract = (address, web3) => {
  return getContract(TomiAbi, address, web3);
};

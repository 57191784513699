import React, { useState,useEffect } from "react";
import "./login.scss";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../utils/ApiUrl";
import Loader from "../../hooks/loader";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useWeb3React } from "@web3-react/core";
import useAuth from "../../hooks/useAuth";
const Login = () => {
  const { login, logout } = useAuth();
  const { account } = useWeb3React();
  const history = useHistory();
  const [loaderr, setLoarder] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [inputFeild1, setInputFeild1] = useState(null);
  const userLogin = async () => {
    // console.log('function call');
    setLoarder(true);
    //  if(inputFeild1){
    const data = {
      code: inputFeild1,
    };
    // if(account && token){
    // console.log("accccc in reduc",account)
    await axios
      .post(API_URL + "/access-codes/verify", data)
      .then(async (res) => {
        localStorage?.setItem("code", res.data.data.code);
        localStorage?.setItem("ownerAddress", res.data.data.ownerAddress);
        localStorage?.setItem("ownerPercentage", res.data.data.ownerPercentage);
        localStorage?.setItem("userPercentage", res.data.data.userPercentage);

        setLoarder(false);
        history.push("/buy");

        console.log(res.data.data);
      })
      .catch((err) => {
        // console.log();
        console.log(err, "err");
        toast.error("code is not valid");
        setLoarder(false);
      });
    // }
  };
  const inputField1Handler1 = (e) => {
    let value = e.target.value;
    setInputFeild1(value);
  };
  useEffect(() => {
    if(account){
      const connectorId = window.localStorage.getItem("connectorId")
       logout(connectorId);
      localStorage.removeItem("connectorId");
      localStorage.removeItem("flag");
    }
  }, [account]);
  return (
    <>
      {loaderr && <Loader />}
      <section className="login-screen">
        <img
          src="\assets\homepage-bg.png"
          alt="img"
          className="img-fluid login-bg"
        />
        <div className="container">
          <div className="upper-logo">
            <img src="\logo.svg" alt="img" className="img-fluid d-none" />
          </div>
          <div className="bottom-card">
            <img
              src="\assets\card-logo.svg"
              alt="img"
              className="img-fluid scvtsvctsc"
            />
            <h6>Enter access code to continue</h6>
            <div className="option-field w-100">
              <input
                onChange={inputField1Handler1}
                value={inputFeild1}
                type="text"
                placeholder="Enter access code"
              />
            </div>
            {inputFeild1 ? (
              <button className="btn-continue" onClick={userLogin}>
                Continue
              </button>
            ) : (
              <button className="newClass">Continue</button>
            )}
          </div>
        </div>
      </section>

      <Modal
        className="connectwallet-modal"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Connect Wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="twice-btns">
            <button>
              <img src="\assets\metamask.svg" alt="img" className="img-fluid" />
              <h6>Metamask</h6>
            </button>
            <button>
              <img
                src="\assets\walletconnect.svg"
                alt="img"
                className="img-fluid"
              />
              <h6>WalletConnect</h6>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Login;

import React, { useEffect, useState } from "react";
import "./claim.scss";
import Navbar from "../landing/header/Navbar";
import { Accordion } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useWeb3React } from "@web3-react/core";
import Loader from "../../hooks/loader";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Claim1 from "../../hooks/chinaFunction/claim";
import ClaimAll from "../../hooks/chinaFunction/claimAll";
import { array } from "i/lib/util";
import { toast } from "react-toastify";
const Claim = () => {
  let now = Date.now();
  const { claim1 } = Claim1();
  const { claimAll } = ClaimAll();
  const [pageCount, setPageCount] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  const [totalArry, settotalArry] = useState([]);
  const { account } = useWeb3React();
  const [mainLoader, setMainLoader] = useState(false);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [check, setCheck] = useState(false);

  const handlePageClick = (e) => {
    setMainLoader(true);
    // console.log('eeeee', e)
    const selectedPage = e.selected;
    // let a = JSON.stringify(inputs?.auctionCounted);
    let skip = "";
    if (e.selected > 0) {
      skip = JSON.stringify(e.selected * limit);
      setPage(selectedPage);
    } else {
      skip = JSON.stringify(0);
      setPage(0);
    }
    console.log("skip", skip, account);
    var data = JSON.stringify({
      query: `query MyQuery {
                investments(
                  first: 10
                  skip: ${skip}
                  where: {By: "${account}"}
                  orderDirection: desc
                  orderBy: PurchaseTime
                ) {
                    totalTomi
                    token
                    ownerFunds
                    isClaimed
                    id
                    currentIndex
                    code_userPercentage
                    code_ownerPercentage
                    code_ownerAddress
                    code_code
                    ReleaseTime
                    PurchaseTime
                    By
                    Bonus
                    AmountTomi
                    AmountSpent
                }
              }`,
    });
    var config = {
      method: "post",
      url: " https://api.studio.thegraph.com/query/41280/tomi-china/version/latest",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        console.log(">>>>>>>>", response);
        // setBids(response?.data?.data?.auctionBids);
        setPageCount(totalLength / limit);
        // console.log(Math.round(totalLength / limit),'pageCountpageCount');
        // console.log(response?.data?.data?.investments,'>>>>>>');
        // console.log(Math.round(totalLength / limit),totalLength,limit,'sdfdsfs');
        // setPageCount(parseInt(response?.data?.data?.auctionBids.length / limit))
        // settotalArry(response?.data?.data?.investments)
        settotalArry(response?.data?.data?.investments);
        ClaimAll111();
        // gata()
        setMainLoader(false);
      })
      .catch(function (error) {
        setMainLoader(false);
      });
    // console.log(“jskdjkfja”, selectedPage)
    // const a = selectedPage + 1
    // getAllUser()
  };
  const nftMainArryHandler1 = async () => {
    setMainLoader(true);
    var data = JSON.stringify({
      query: `query MyQuery {
            investments(
              where: {By: "${account}"}
              orderDirection: desc
              orderBy: PurchaseTime
            ) {
                totalTomi
                token
                ownerFunds
                isClaimed
                id
                currentIndex
                code_userPercentage
                code_ownerPercentage
                code_ownerAddress
                code_code
                ReleaseTime
                PurchaseTime
                By
                Bonus
                AmountTomi
                AmountSpent
            }
          }`,
      variables: {},
    });
    var config = {
      method: "post",
      url: " https://api.studio.thegraph.com/query/41280/tomi-china/version/latest",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
      .then(async function (response) {
        console.log(">>>>>>>>", response,response?.data?.data?.investments?.length);
        setPageCount(response?.data?.data?.investments?.length / limit);

        setTotalLength(response?.data?.data?.investments?.length);
        // settotalArry(response?.data?.data?.publicVotes)
        setMainLoader(false);
        // nftMainArryHandler()
      })
      .catch(function (error) {
        setMainLoader(false);
        console.log(error, "error");
        // setstackingTime('')
      });
  };
  const nftMainArryHandler2 = async () => {
    setMainLoader(true);
    var data = JSON.stringify({
      query: `query MyQuery {
            investments(
              where: {By: "${account}"}
              first: 10
              skip: 0
              orderBy: PurchaseTime
              orderDirection: desc
            ) {
                totalTomi
                token
                ownerFunds
                isClaimed
                id
                currentIndex
                code_userPercentage
                code_ownerPercentage
                code_ownerAddress
                code_code
                ReleaseTime
                PurchaseTime
                By
                Bonus
                AmountTomi
                AmountSpent
            }
          }`,
      variables: {},
    });
    var config = {
      method: "post",
      url: " https://api.studio.thegraph.com/query/41280/tomi-china/version/latest",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
      .then(async function (response) {
        console.log(">>>>>>>>", response);
        settotalArry(response?.data?.data?.investments);
        setMainLoader(false);
        ClaimAll111();
        // nftMainArryHandler()
      })
      .catch(function (error) {
        setMainLoader(false);
        console.log(error, "error");
        // setstackingTime('')
      });
  };
  useEffect(() => {
    nftMainArryHandler1();
    nftMainArryHandler2();
  }, [account]);

  const [show, setShow] = useState(false);
  const history = useHistory();
  let checkadmin = localStorage?.getItem("code");

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!checkadmin) {
      history.push("/");
    }
  }, []);
  const handler121 = async (e) => {
    setMainLoader(true);
    console.log(e);
    let z = e.currentIndex;
    try {
      let t = await claim1(z);
      if (t) {
        setMainLoader(false);
        window.location.reload();
      }
    } catch (err) {
      console.log(err, "err");
      setMainLoader(false);
      toast.error("Transaction reverted");
    }
  };
  const ClaimAll11 = async (e) => {
    // console.log(e);

    //    let z= e.currentIndex
    let dummy = [];
    console.log(totalArry, "totalArry");
    totalArry.map((e, i) => {
      if (now > e.ReleaseTime * 1000 && e.isClaimed == false) {
        console.log(e, "e");
        dummy.push(e.currentIndex);
      }
    });
    console.log(dummy, "fsefsdf");
    if (dummy?.length > 0) {
      setMainLoader(true);
      try {
        let b = await claimAll(dummy);
        if (b) {
          setMainLoader(false);
          window.location.reload();
        }
      } catch (err) {
        setMainLoader(false);
        console.log(err, "err");
        toast.error("Transaction reverted");
      }
    }
  };
  const ClaimAll111 = async (e) => {
    // console.log(e);

    //    let z= e.currentIndex
    let dummy = [];
    console.log(totalArry, "totalArry");
    totalArry.map((e, i) => {
      if (now > e.ReleaseTime * 1000 && e.isClaimed == false) {
        console.log(e, "e");
        dummy.push(e.currentIndex);
      }
    });
    console.log(dummy, "fsefsdf");
    if (dummy?.length > 0) {
      setCheck(true);
    } else {
      setCheck(false);
    }
  };
  console.log(pageCount, "pageCount");
  return (
    <>
      {mainLoader && (
        <Loader text={"Please wait, your transaction is in process"} />
      )}
      <Navbar show={show} setShow={setShow} />
      <section className="claim">
        <div className="custom-container">
          <div className="claim-heading">
            <h6>Claim TOMI</h6>
            {totalArry?.length>0?
            <>
                  {check ? (
                    <button className="btn-claim noor">Claimed</button>
                  ) : (
                    <button className="btn-claim" onClick={ClaimAll11}>
                      Claim All
                    </button>
                  )}
              
              
              </>:
              ""
            }
      
          </div>
          <div className="bottom-table">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>Purchased on</th>
                    {/* <th>Code</th>
                    <th>Owner Address</th>
                    <th>Owner Percentage</th>
                    <th>User Percentage</th> */}

                    <th>Amount Spent</th>
                    <th>Amount Purchased</th>

                    <th>% Bonus </th>
                    <th>Total</th>
                    <th>Available on</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {totalArry?.map((e) => {
                    let a = parseInt(e?.PurchaseTime);
                    let cc = new Date(a);
                    const StackIntialDate = moment(cc * 1000).format(
                      "DD-MM-YYYY H:mm"
                    );
                    let cc1 = new Date(parseInt(e.ReleaseTime));
                    const StackIntialDate1 = moment(cc1 * 1000).format(
                      "DD-MM-YYYY H:mm"
                    );
                    console.log(
                      cc,
                      StackIntialDate,
                      "StackIntialDate",
                      parseInt(e?.PurchaseTime)
                    );
                    let puchased = 0;
                    if (e.token === "ETH") {
                      puchased = (parseInt(e.AmountSpent) / 10 ** 18).toFixed(
                        4
                      );
                    } else {
                      puchased = (parseInt(e.AmountSpent) / 10 ** 6).toFixed(4);
                    }

                    return (
                      <>
                        <tr>
                          <td>{StackIntialDate}</td>
                          {/* <td>{e.code_code}</td>
                          <td>{e.code_ownerAddress}</td>
                          <td>{(e.code_ownerPercentage / 10).toFixed(2)}%</td>
                          <td>{(e.code_userPercentage / 10).toFixed(2)}%</td> */}
                          <td>
                            {puchased} {e.token}
                          </td>
                          <td>
                            {(parseInt(e.AmountTomi) / 10 ** 18).toFixed(4)}{" "}
                            TOMI
                          </td>
                          <td>
                            {(parseInt(e.Bonus) / 10 ** 18).toFixed(4)} TOMI
                          </td>
                          <td>
                            {(parseInt(e.totalTomi) / 10 ** 18).toFixed(4)} TOMI
                          </td>
                          <td>{StackIntialDate1}</td>
                          {now > e.ReleaseTime * 1000 ? (
                            <>
                              {e.isClaimed == false ? (
                                <td>
                                  <button
                                    className="btn-claim"
                                    onClick={() => {
                                      handler121(e);
                                    }}
                                  >
                                    Claim
                                  </button>
                                </td>
                              ) : (
                                <td>
                                  <button className="btn-claim claimed">
                                    Claimed
                                  </button>
                                </td>
                              )}
                            </>
                          ) : (
                            <td>
                              <button className="btn-claim disable">
                                Claim
                              </button>
                            </td>
                          )}
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
              <div className="footer-content">
                <div className="left-f">
                  {/* <h6>Showing 1 to 10 of 57 entries</h6> */}
                  <h6 className="paginationtext">
                    SHOWING {page + 1}-{limit} OF {total}
                  </h6>
                </div>
                <div className="right-f">
                  <nav aria-label="Page navigation example">
                    {pageCount >= 1 ? (
                      <div className="text-center">
                        <ReactPaginate
                          previousLabel={<img src="\assets\arrow1.svg" />}
                          nextLabel={<img src="\assets\arrow2.svg" />}
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={handlePageClick}
                          containerClassName="pagination"
                          activeClassName="active"
                          forcePage={page}
                        ></ReactPaginate>
                      </div>
                    ) : (
                      ""
                    )}
                  </nav>
                </div>
              </div>
            </div>
            <div className="accmblview d-none">
              <h6 className="accmblviewhead">
                Exchange{" "}
                <div className="arrowsacc">
                  <img
                    src="\Assets\tomitoken-assets\tableup.svg"
                    alt="tableupr"
                    className="tableup"
                  />
                  <img
                    src="\Assets\tomitoken-assets\tabledown.svg"
                    alt="tabledown"
                    className="tabledown"
                  />
                </div>
              </h6>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <div className="accheadermain">
                      <div className="accmainimage">
                        <img
                          src="\Assets\tomitoken-assets\exchangeimageone.svg"
                          alt="accinnerimg"
                          className="accinnerimg img-fluid"
                        />
                      </div>
                      <p className="acctext">Huobi</p>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="acctexts">
                      <h6 className="textleft">Price</h6>
                      <p className="textright">$4.7546</p>
                    </div>
                    <div className="acctexts">
                      <h6 className="textleft">+2% Depth</h6>
                      <p className="textright">$677</p>
                    </div>
                    <div className="acctexts">
                      <h6 className="textleft">-2% Depth</h6>
                      <p className="textright">$2,605</p>
                    </div>
                    <div className="acctexts">
                      <h6 className="textleft">Volume (24h)</h6>
                      <p className="textright">$277,193</p>
                    </div>
                    <div className="acctexts">
                      <h6 className="textleft">Volume</h6>
                      <p className="textright">26.60%</p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Claim;

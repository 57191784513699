import "./navbar.scss";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import useAuth from "../../../hooks/useAuth";
import Offcanvas from 'react-bootstrap/Offcanvas';


const Navbar = ({ show, setShow }) => {


  const { login, logout } = useAuth();
  const { account } = useWeb3React();
  const handleClose = () => setShow(false);
  const handleShow = () => {
    console.log(setShow, "setShow", show);
    if (setShow) {
      setShow(true);
    }
  };
  const trustWallet = async () => {
    if (account) {
      await logout("walletconnect");
      localStorage.clear();
    } else {
      login("walletconnect");
      handleClose()

      // gettingSign()
      localStorage.setItem('connectorId', 'walletconnect');
      localStorage.setItem("flag", "true");
      // handleClose()
    }
  };

  const connectMetaMask1 = async () => {
    if (account) {
      const connectorId = window.localStorage.getItem("connectorId")
      await logout(connectorId);
      localStorage.removeItem("connectorId");
      localStorage.removeItem("flag");
    } else {
      login("injected");
      localStorage.setItem("connectorId", "injected");
      localStorage.setItem("flag", "true");
      handleClose();
      // setAcount11(account)
      // window.location.reload()

      
    }
  };
  const clearData = () => {
    localStorage.removeItem("connectorId");
    localStorage.removeItem("flag");
    // logout();
  };


  const [showw, setShoww] = useState(false);
  const handleClosew = () => setShoww(false);
  const handleShoww = () => setShoww(true);


  return (
    <>
      <section className="main-navbar">
        <div className="custom-container">
          <nav class="navbar navbar-expand-lg p-0">
            <a class="navbar-brand" href="/buy">
              <img src="\logo.svg" alt="img" className="img-fluid" />
            </a>
            <button
              class="navbar-toggler"
              type="button"
              onClick={handleShoww}
            >
              <span class="navbar-toggler-icon">
                <img
                  src="\assets\navbar-bar.svg"
                  alt="img"
                  className="img-fluid"
                />
              </span>
            </button>
            <div
              class="collapse navbar-collapse justify-content-end"
              id="navbarSupportedContent"
            >
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                  <a href="/buy" class="nav-link active" aria-current="page">
                    Buy
                  </a>
                </li>
                <li class="nav-item">
                  <a href="/claim" class="nav-link">
                    Claim
                  </a>
                </li>
              </ul>
              {!account ? (
                <button className="connect-btn" onClick={handleShow}>
                  Connect Wallet
                </button>
              ) : (
                <button className="connect-btn" onClick={connectMetaMask1}>
                  Disconnect
                </button>
              )}
            </div>
          </nav>
        </div>
      </section>

      <Modal
        className="connectwallet-modal"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Connect Wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="twice-btns" >
            <button className="forhideee" onClick={connectMetaMask1}>
              <img src="\assets\metamask.svg" alt="img" className="img-fluid" />
              <h6>Metamask</h6>
            </button>
            <button onClick={trustWallet}>
              <img
                src="\assets\walletconnect.svg"
                alt="img"
                className="img-fluid"
              />
              <h6>WalletConnect</h6>
            </button>
          </div>
        </Modal.Body>
      </Modal>


      <Offcanvas className="mobile-navbar" show={showw} onHide={handleClosew}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title> <img src="\logo.svg" alt="img" className="img-fluid" /> </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
         <a href="/buy">Buy</a>
         <a href="/claim">Claim</a>
         {/* <button onClick={() => {
          handleClosew();
          handleShow();
         }}>Connect Wallet</button> */}  {!account ? (
                <button className="connect-btn" onClick={() => {
                  handleClosew();
                  handleShow();
                 }}>
                  Connect Wallet
                </button>
              ) : (
                <button className="connect-btn" onClick={connectMetaMask1}>
                  Disconnect
                </button>
              )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Navbar;

import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { chinaContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";

const PurchaseEth = () => {
  const [balance, setBalance] = useState(null);
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress = Environment.chinaTomi;
  const contract = chinaContract(tokenAddress, web3);
  let checkadmin = localStorage?.getItem("code");

  const purchaseEth = useCallback(
    async (amount, postApiRes) => {
      amount =parseFloat(amount).toFixed(6)
          let amount1 = Math.round((amount) * 1e12) / 1e12;
      let nd = web3.utils.toWei(amount1.toString(), "ether");
      // console.log(nd,'>>>>>>',amount);
      // console.log(a,b,c,d,e,r,'a,b,c,d,e');
      //   amount = amount.toString()
      // console.log(interAccount,random.toString(),time.toString(),v,r,s,'interAccount,random.toString(),time.toString(),v,r,s');

      let tuple = {
        code: postApiRes?.code,
        ownerAddress: postApiRes?.ownerAddress,
        ownerPercentage: postApiRes?.ownerPercentage.toString(),
        userPercentage: postApiRes?.userPercentage.toString(),
      };
      try {
        console.log("herere",contract)
         let gasPrice=await web3.eth.getGasPrice()
         gasPrice = parseInt(gasPrice) + 3000000000
         console.log(gasPrice,'gasPrice');
        const gas = await contract.methods
          .purchaseWithEth(tuple, postApiRes?.v, postApiRes?.r, postApiRes?.s)
          .estimateGas({
            value: nd,
            from: account,
            // gasPrice
          });
          // gasPrice=parseInt(gasPrice)+15000000000
        const details = await contract.methods
          .purchaseWithEth(tuple, postApiRes?.v, postApiRes?.r, postApiRes?.s)
          .send({
            value: nd,
            from: account,
            gas:gas,
            gasPrice
          });
        return details;
      } catch (error) {
        throw error;
      }
    },
    [contract, account]
  );
  return { purchaseEth: purchaseEth };
};
export default PurchaseEth;

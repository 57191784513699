import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { usdtContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";

const BalacefAccount = () => {
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress1 = Environment.busd;
  const contract = usdtContract(tokenAddress1, web3);
  const balacefAccount = useCallback(
    async () => {
        // console.log(tokenAddress,account,'noor');
      try {
        const buy = await contract.methods.balanceOf(account).call();
        // console.log('sdsdss' ,  buy)
        return buy/10**6;
      } catch (error) {
        throw error;
      }
    },
    [contract, account]
  );
  return { balacefAccount:balacefAccount };
};
export default BalacefAccount;
import './App.scss';
import Landing from './components/landing/Landing.js';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './components/Login/Login';
import Claim from './components/Claim/Claim';
import useEagerConnect from "./hooks/useEagerConnect";
function App() {
  useEagerConnect(); 
  return (
    <>
  <ToastContainer autoClose={5000} style={{ fontSize: 12, fontWeight: 300 }} theme="light" position="top-center" />
          <Router>
            <Switch>
              <Route exact path='/' component={Login} />
              <Route exact path='/buy' component={Landing} />
              <Route exact path='/claim' component={Claim} />
            </Switch>
          </Router>
    </>
  );
}

export default App;
